import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, map, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Delivery, DeliveryCategory } from '../../models/delivery';


@Injectable({
  providedIn: 'root'
})
export class DataGridService {
  public _page$ = new BehaviorSubject<Pageable>({ page: 0, pageSize: 30 });
  protected _filters$ = new BehaviorSubject<Filters>({});
  private params?:object
  
  get page$(): Observable<Pageable> {
    return this._page$.asObservable();
  }

  get filters$(): Observable<Filters> {
    return this._filters$.asObservable();
  }

  constructor(private http: HttpClient) {}

  private apiUrl = environment.apiUrl;

  convertToURLparams(filters?: { key: string, value: string, sub_cat_value?:string }[]) {
    const params = new URLSearchParams();
    const page = this._page$.getValue();
    if (filters) {
      filters.forEach(filter => {
        params.append(filter.key, filter.value);
        if(filter.sub_cat_value !== undefined){
          params.append('type', filter.sub_cat_value)
        }
      });
    }
    params.append('limit', page.pageSize.toString())
    params.append('offset', (page.page * page.pageSize).toString());
    return params
  } 

  getData(
    filters?: { key: string, value: string, sub_cat_value?:string }[]
  ): Observable<PagedResponseList<Delivery>> {
    
    const params = this.convertToURLparams(filters)
    const urlWithParams = `${this.apiUrl}/reuse-center/delivery-list?${params.toString()}`;
    return this.http.get<PagedResponseList<Delivery>>(urlWithParams);

  }

  getFilteredData(filters: { key: string, value: string, sub_cat_value?:string }[]): Observable<PagedResponseList<Delivery>> {//(filters: { key: string, value: string, sub_cat_value?:string }):
    const params = new URLSearchParams();
    filters.forEach(filter => {
      params.append(filter.key, filter.value);
      if(filter.sub_cat_value !== undefined){
        params.append('type', filter.sub_cat_value)
      }
    });
    this.setFirstPage();
    return this.getData(filters)
    //return this.http.get<PagedResponseList<Delivery>>(urlWithParams);
  }



  setFirstPage() {
    const page = this._page$.getValue();
    this._page$.next({ ...page, page: 0 });
  }

  setPage(page: Pageable) {
    this._page$.next(page);
    this.getData();
  }




  get categorie$() {
    return this.http.get<PagedResponseList<DeliveryCategory>>(`${this.apiUrl}/reuse-center/categorie`).pipe(map((res) => res.results));
  }
}

export type PagedResponseList<T> = {
  count: number;
  results: T[];
};

export type Pageable = {
  page: number;
  pageSize: number;
};

type Filters = { [key: string]: unknown };