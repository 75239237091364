import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { filter, map, Observable, startWith, switchMap, tap, MonoTypeOperatorFunction, Subject  } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-grid-filters',
  templateUrl: './grid-filters.component.html',
  styleUrls: ['./grid-filters.component.scss'],
})

export class GridFiltersComponent implements OnInit, OnDestroy{
  private unsubscribeSubject: Subject<void> = new Subject();
  selectedKey!: FilterField;
  value: string = '';
  selectedValue= 0;
  sub_cat_value?:string[]
  chip_value?:string;
  keys: FilterField[] = [];
  options: FilterFieldOption[]=[];
  subcategory:NestedJson[]=[];
  filteredOptions!: Observable<FilterFieldOption[]>;

  filterFormGroup: FormGroup = this.formBuilder.group({
    key: [null, [Validators.required]],
    value: [null, [Validators.required]],
    sub_cat_value:[null]
    //cod: [null]
  });

  get selectedField(): FilterField | null{
    return this.filterFormGroup.value?.key
    //return {name: 'delivery_code', type: 0, text: 'Codice'}
  }

  get FieldType(): typeof FieldType {
    return FieldType;
  }

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<GridFiltersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.keys = data.keys;
  }

  applyFilter(): void {
    this.filterFormGroup.markAllAsTouched();
    var chip_data=undefined;
    /*if (this.filterFormGroup.value.key.name==='delivery_category'){
      chip_data=this.set_chip(this.filterFormGroup.value.value)[0].description
    }*/
    if (this.selectedKey && this.filterFormGroup.value.value) {
      var sub_cat:string=''
      if(this.sub_cat_value !== undefined){
        for (var sub in this.sub_cat_value){
          if (sub_cat === ''){
            sub_cat=this.sub_cat_value[sub]
          }else{
            sub_cat=sub_cat+','+this.sub_cat_value[sub]
          }
        }
      }
      if(sub_cat !== ''){
        const filter = { key: this.selectedKey.name, value: this.filterFormGroup.value.value, chip_value: chip_data, sub_cat_value:sub_cat};
        this.dialogRef.close(filter);
      }else{
        const filter = { key: this.selectedKey.name, value: this.filterFormGroup.value.value, chip_value: chip_data, sub_cat_value:this.sub_cat_value};
        this.dialogRef.close(filter);
      }
    }
  }

  set_chip(id:number){
    return this.options?.filter((option) =>
        option?.id==id)
  }
  get_subcategory(id:number){
    return this.options?.filter((option) =>
        option?.id === id
      )[0].json;
  }

  capitalizeLetter(str:string){
    if (str.length > 0) {
      return str[0].toUpperCase() + str.slice(1);
    } else {
      return null
    }
  }
  protected onDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }

  ngOnDestroy() {
    this.onDestroy();
  }


  private _filterOptions(
    value: string | FilterFieldOption
  ): FilterFieldOption[] {
    if (typeof value === 'string') {
      const search = value.toUpperCase();
      return this.options?.filter((option) =>
        option?.description.toUpperCase().includes(search)
      );
    }
    return [];
  }

  ngOnInit(): void {
    const formValue=this.filterFormGroup.get('value')
    if(formValue !== null){  
      this.filteredOptions = formValue.valueChanges.pipe(
        startWith(''),
        map((val: string | FilterFieldOption) => this._filterOptions(val))
      );
    }
    const key=this.filterFormGroup.get('key') 
    if(key !== null){
      key
      .valueChanges.pipe(filter((val) => val.options))
      .pipe(switchMap((val) => val.options as Observable<FilterFieldOption[]>))
      .pipe(this.autoDispose())
      .subscribe((res) => (this.options = res));
    }

  }


  displayFn() {
    return (groupId: string | number) =>
      this.options?.find((item) => item.description === groupId)?.description || this.options?.find((item) => item.id === groupId)?.description!; 
  }


  cancel(): void {
    this.dialogRef.close();
  }

  protected autoDispose<T>(): MonoTypeOperatorFunction<T> {
    return takeUntil(this.unsubscribeSubject);
  }
}
export type FilterField = {
  name: string;
  text: string;
  type: FieldType;
  options?: Observable<FilterFieldOption[]>;
};

export type FilterFieldOption = { id: number; description: string, json?:NestedJson[] };

export type NestedJson ={ cod:string; tipologia: string;}

export enum FieldType {
  TEXT,//this.filterFormGroup.value.field.type===0
  AUTOCOMPLETE,//this.filterFormGroup.value.field.type===1
  MATSELECT,
  UPPERCASE,
}