import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { DataGridComponent } from './features/data/data-grid/data-grid.component';
import { HttpClientModule } from '@angular/common/http';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { ImgDialogComponent } from './features/img-dialog/img-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import {MatListModule} from '@angular/material/list';
import { TranslateService } from '@ngx-translate/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
//import { translateLoaderFactory } from '../i18n/i18n.module';
import { HttpClient } from '@angular/common/http';
import { I18nModule, translateLoaderFactory } from './i18n/i18n.module';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RootStoreModule } from './store/store.module';
import { HeaderComponent } from './features/layout/header/header.component';
import { FooterComponent } from './features/layout/footer/footer.component';
import { NavigationListItemComponent } from './features/layout/navigation-menu/components/navigation-list-item/navigation-list-item.component';
import { NavigationMenuItemComponent } from './features/layout/navigation-menu/components/navigation-menu-item/navigation-menu-item.component';
import { NavigationMenuComponent } from './features/layout/navigation-menu/navigation-menu.component';
import { ContentLayoutComponent } from './features/layout/content-layout/content-layout.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { DataGridModule } from './features/data/data-grid.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { GridFiltersComponent } from './features/layout/grid-filters/grid-filters.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WorkingHourModule } from './features/working-hour/working-hours.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CookiesModule } from './features/cookies/cookies.module';
import { PrivacyPolicyModule } from './features/privacy-policy/privacy-policy.module';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ImgDialogComponent,
    NavigationListItemComponent,
    NavigationMenuItemComponent,
    NavigationMenuComponent,
    ContentLayoutComponent,
    GridFiltersComponent,
  ],
  imports: [
    MatAutocompleteModule,
    MatFormFieldModule,
    FormsModule,
    WorkingHourModule,
    PrivacyPolicyModule,
    ReactiveFormsModule,
    MatInputModule,
    MatChipsModule,
    MatButtonModule,
    MatSelectModule,
    FlexLayoutModule,
    BrowserModule,
    AppRoutingModule,
    DataGridModule,
    CookiesModule,
    RootStoreModule,
    MatSidenavModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatGridListModule,
    MatDialogModule,
    MatMenuModule,
    MatListModule,
    MatToolbarModule,
    MatTooltipModule,
    I18nModule,
    MatIconModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
