import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppActions } from 'src/app/store/app-store';
import { Menu } from '../../navigation-menu.component';

@Component({
  selector: 'app-navigation-list-item',
  templateUrl: './navigation-list-item.component.html',
  styleUrls: ['./navigation-list-item.component.scss'],
})
export class NavigationListItemComponent {
  @Input() item?: Menu;

  constructor(private store: Store) {}

  hideMenu() {
    this.store.dispatch(AppActions.hideNav());
  }
}
