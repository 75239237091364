import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { map, tap } from 'rxjs';

@Component({
  selector: 'app-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationMenuComponent implements OnInit, OnChanges {
  public menu: Map<string, Menu[]> = new Map();
  //@Input() item?: Menu;
  currentMenu?: Menu[];

  constructor(private translate: TranslateService) {
    this.menu.set('it', it);
  }

  ngOnChanges(): void {
    //this.currentMenu = this.menu.get(this.translate.currentLang)
    this.currentMenu = this.authorizedMenu(
      this.menu.get(this.translate.currentLang)
    );
  }

  ngOnInit(): void {
    this.currentMenu = this.authorizedMenu(
      this.menu.get(this.translate.currentLang)
    );
    this.translate.onLangChange.pipe(
      map((langEvent) => this.menu.get(langEvent.lang)),
      map((menu) => this.authorizedMenu(menu)),
      tap((menu) => (this.currentMenu = menu))
    );
  }

  authorizedMenu(menu: Menu[] | undefined): Menu[] | undefined {
    if (menu != undefined){
      return menu
      .map((menu) => this.menuWithoutUnauthorizedChild(menu))
      .filter((menu) => menu.items.length > 0);
    }
    else {
      console.error(`Menu items for language are undefined.`);
      return menu=[]
    }
  }

  menuWithoutUnauthorizedChild(menu: Menu): Menu {
    return {
      ...menu,
      items: menu.items,
    };
  }

}

export type Menu = {
label: string;
items: MenuChild[];
};

export type MenuChild = {
label: string;
link: string[];
};


const it: Menu[] = [
  {
    label: 'Articoli',
    items: [
      {
        label: 'Articoli',
        link: ['/home'],
      }
    ],
  },
  {
    label: 'Orari',
    items: [
      {
        label: 'Orari apertura',
        link: ['/orari'],
      }
    ],
  },
]
