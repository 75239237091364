<div class="container">
    <h1>Cookie Policy</h1>
    <h2>UTILIZZO DEI COOKIES</h2>
    <p>Il sito web, per un miglior funzionamento e per garantire servizi erogati da terze parti utilizza Cookies rilasciati dal TITOLARE o da terze parti.</p>
    <h3>I cookie rilasciati dal TITOLARE</h3>
    <p>I cookies rilasciati dal TITOLARE sono “tecnici” e sono quelli utilizzati al solo fine di “effettuare la trasmissione di una comunicazione su una rete di comunicazione elettronica, o nella misura strettamente necessaria al fornitore di un servizio della società dell’informazione esplicitamente richiesto dall’abbonato o dall’utente a erogare tale servizio”. Essi non vengono utilizzati per scopi ulteriori, e vengono installati con la normale navigazione nelle nostre pagine. Possono essere suddivisi in cookie di navigazione o di sessione, che garantiscono la normale navigazione e fruizione del sito web (permettendo ad esempio la realizzazione di un acquisto o l’autenticazione e l’accesso alle aree riservate); cookie di funzionalità, che permettono all’utente la navigazione in funzione di una serie di criteri selezionati (ad esempio, la lingua, i prodotti selezionati per l’acquisto ecc.) al fine di migliorare il servizio reso allo stesso. Per l’installazione di tali cookie non è richiesto il preventivo consenso degli utenti, mentre resta fermo l’obbligo di dare la presente informativa ai sensi dell’art. 13 del Reg. 679/16.</p>
    <h3>Cookies di terze parti per finalità statistiche o tecniche</h3>
    <p>Nel corso della navigazione sul sito, l’utente può ricevere sul suo terminale anche cookie di fornitori diversi (c.d. cookie di “terze parti”); ciò accade perché sul sito web visitato possono essere presenti elementi che risiedono su server diversi da quello sul quale si trova la pagina richiesta.

        IL TITOLARE usa cookies di terze solo per finalità tecniche, ad esempio cookies rilasciati per analizzare statisticamente gli accessi/le visite al sito (cookie cosiddetti “analytics”) o per permettere l’utilizzo di parti di codice rilasciate da soggetti terzi, che garantiscono un miglior uso del servizio (autenticazioni, pagine di amministrazione ecc.).
        
        I cookies di terze parti utilizzati in questo sito e le modalità per gestire l’opt out (la disattivazione dei medesimi) sono di seguito indicati:
        
            Google Analytics: servizio di analisi del traffico web fornito da Google, Inc. (“Google”). Tali cookie sono usati al solo fine di monitorare e migliorare le prestazioni del sito. Per ulteriori informazioni, si rinvia al link di seguito indicato: https://www.google.it/policies/privacy/partners/
        
        L’utente può disabilitare in modo selettivo l’azione di Google Analytics installando sul proprio browser la componente di opt-out fornito da Google. Per disabilitare l’azione di Google Analytics, si rinvia al link di seguito indicato: https://tools.google.com/dlpage/gaoptout</p>
    <h3>Cookies di terze parti (es. social network)</h3>
    <p>Nel corso della navigazione sul sito, l’utente può ricevere cookie di società terze (c.d. cookie di “terze parti”) anche per ulteriori finalità non tecniche. Il sito, infatti, integra funzionalità sviluppate da terzi all’interno delle proprie pagine, come le icone e le preferenze espresse nei social network, al fine di condivisione dei contenuti del sito. I cookies di terze parti utilizzati per tali finalità in questo sito, la relativa informativa completa e la possibilità di gestire il consenso, le modalità per gestire l’opt out (la disattivazione dei medesimi) sono riportati sui siti dei rispettivi titolari (Google, Facebook, Twitter ecc.)

        Per disabilitare tali cookies è inoltre possibile utilizzare idonee funzionalità e tool sul Suo Browser, ovvero avvalersi dei servizi resi dal sito http://www.youronlinechoices.com/it/ gestito dalla European Interactive Digital Advertising Alliance (EDAA) che costituisce una guida sulla pubblicità comportamentale e la privacy online e dove troverete informazioni su come funziona la pubblicità comportamentale e molte informazioni su come gestire e disabilitare cookie oltre alle tappe da seguire per proteggere la privacy su internet.
        
        Continuando in qualsiasi modo la navigazione dalla home page del sito, lei consente all’uso dei cookies di terze parti in questo sito, per le finalità indicate nell’informativa.</p> 
</div>