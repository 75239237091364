<div class="footer" fxLayout="row" fxLayoutAlign="center">
  <img src="assets/images/logo_net.png" alt="Image"/>
  <div class="container wow fadeIn" style="visibility: visible" fxLayout="row" >
    <p class="title-size bold">NET S.p.A.</p>
    <div class="row w-100 flex">
      <div class="col col-sx-2">
        <ul class="list-bare list-bare-smaller" >
          <li><span class="bold">Sede Operativa</span></li>
          <li>Viale Giuseppe Duodo 3/E</li>
          <li>33100 Udine</li>
          <li>Italia</li>
        </ul>
      </div>
      <div class="col col-sx-2" fxLayout="row" >
        <ul class="list-bare list-bare-smaller">
          <li><span class="bold">Contatti</span></li>
          <li>Tel: 800 520 406</li>
        </ul>
      </div>
      <div class="col col-sx-2">
        <ul class="list-bare list-bare-smaller">
          <li><b>P.IVA/CF: </b> 94015790309</li>
          <li><b>C.S.: </b> € 9.776.200,00 i.v.</li>
        </ul>
      </div>
      <p class="title-size bold">Powered by MQUADRO S.R.L.</p>
    </div>
    <!--<p class="title-size bold">Powered by MQUADRO S.R.L.</p>-->
  </div>
</div>
