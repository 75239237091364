<div class="container" >
    <h1>DATI GENERALI DEL CENTRO DI RIUSO</h1>
    <ul>
        <li><h3><b>Indirizzo</b> Via Rizzolo – Centro di Riuso del Comune di Udine</h3></li>
        <li><h3><b>Frequenza di apertura</b></h3> il <b>sabato</b> dalle ore <b>8.00 alle ore 18.00</b> (dal 1° aprile al 31 ottobre); <b>dalle
            8.00 alle 17.00</b> (dal 1° novembre al 31 marzo)</li>
    </ul>
    <h1> Regolamento</h1>
        <p>I soggetti che possono fruire del CdRi (fruitori) sono:</p>
        <ul>
            <li>I. Conferitore: privato cittadino, impresa o ente, titolari di una posizione TARI nel comune
                di Udine che, in possesso di un bene usato con caratteristiche di cui all’art 1, comma 4
                del presente regolamento, lo consegni al CdRi affinché ne possa essere prolungato il
                ciclo di vita mediante la cessione gratuita a terzi.</li>
            <li>II. Utente: privato cittadino, associazione di volontariato Onlus, organismo no profit, istituto
                scolastico che preleva un bene dal CdRi al fine di un suo riuso.
                I beni possono essere prelevati/consegnati soltanto dai soggetti individuati al comma 1 del
                presente articolo.</li>
            <li>Al fine di evitare situazioni di accaparramento, ancorché per finalità non commerciali, il
                prelievo di beni è limitato, a n. 8 oggetti al mese per fruitore. Il gestore avrà la facoltà di
                limitare eventuali prelievi in caso riscontri delle anomalie nei ritiri.</li>
            <li>Al fine di contrastare eventuali gestioni improprie di beni aziendali, il conferimento di beni è
                limitato a <b>nr. 2 oggetti al mese per fruitore non domestico.</b></li>
        </ul>
        <p>Sono ammessi all’interno del CdRi esclusivamente i materiali e oggetti suscettibili di riuso
            appositamente selezionati dal personale incaricato. I beni sono accettati al Centro di Riuso
            nel rispetto della disciplina in materia di prevenzione incendi, di tutela della salute e della
            sicurezza nei luoghi di lavoro e di tutte le norme applicabili e sono classificati secondo le
            seguenti tipologie:</p>
        <ul>
            <li>Mobili ed elementi di arredo (esclusi beni che per dimensioni impediscono la regolare
                attività del Centro di Riuso)</li>
            <li>Stoviglie e casalinghi quali: servizi di piatti, pentole, posate e altri utensili da cucina;
                - Attrezzature sportive.</li>
            <li>Oggettistica quale: quadri e cornici, soprammobili;</li>
            <li>Giocattoli.</li>
            <li>Articoli per l’infanzia quali: culle e seggiolini, giochi per bambini, passeggini;</li>
            <li>Biciclette (entro i limiti di spazio disponibili e nell’ordine primariamente sono ammesse
                le biciclette per bambini)</li>
            <li>Libri e pubblicazioni.</li>
        </ul>
        <p>
           <b>I seguenti beni sono vietati:</b>
        </p>
        <ul>
            <li>Scarpe</li>
            <li>Indumenti/abbigliamento</li>
            <li>Materassi</li>
            <li>Cuscini da letto</li>
            <li>Elettrodomestici di qualunque tipologia</li>
            <li>Apparecchi ICT di qualunque tipologia (es. smartphone, tablet, ecc.)</li>
        </ul>
</div>
