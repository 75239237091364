<div class="grid-filters">
    <form
      [formGroup]="filterFormGroup"
      fxLayout="row"
      aria-describedby="portal group table"
      class="form-field"
    >
      <ng-container>
        <mat-form-field fxFlex >
          <mat-label>Tipologia</mat-label>
          <input matInput formControlName="value" type="search"/>
          <button matSuffix mat-icon-button aria-label="Search" (click)="applyFilter()" class="filter-button"><mat-icon>search</mat-icon></button>
        </mat-form-field>
      </ng-container>
      <!--<div style="display: inline; padding-left:1%">
        <button mat-button mat-raised-button (click)="applyFilter()" class="filter-button"><mat-icon>search</mat-icon><p style="font-size: medium; display: inline; vertical-align: center; padding-bottom: 1%;">Applica</p></button>
      </div>-->
    </form>
  </div>
  <!--<div mat-dialog-actions class="footer-filters">
    <button mat-button style="color:white;" (click)="applyFilter()">Applica</button>
  </div>-->