import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { delay, Observable, of } from 'rxjs';
import { AppActions, AppSelectors } from 'src/app/store/app-store';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentLayoutComponent implements OnInit {
  @ViewChild('content', { static: true })
  private content!: TemplateRef<unknown>;

  showNav$?: Observable<boolean>;


  constructor(
    private store: Store,
    private breakpointObserver: BreakpointObserver
  ) {}

  /**
   * Restituisce il template attivo in funzione dello stato dell'app
   */
  get template$(): Observable<TemplateRef<unknown>> {
    return of(this.content);
  }

  get loading$(): Observable<boolean> {
    return this.store.select(AppSelectors.selectLoading).pipe(delay(0));
  }

  ngOnInit(): void {
    this.showNav$ = this.store.select(AppSelectors.selectNavbar);

    this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.Medium])
      .subscribe((result) => {
        if (!result.matches) this.store.dispatch(AppActions.hideNav());
      });
  }

  public closeSidebar() {
    this.store.dispatch(AppActions.hideNav());
  }
}
