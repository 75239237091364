import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './app-store.state';

export const FEATURE_APP = 'app';

export const selectAppState = createFeatureSelector<State>(FEATURE_APP);

export const selectUser = createSelector(selectAppState, (state) => state);
export const selectLoading = createSelector(
  selectAppState,
  (state) => state.loadingCount > 0
);

export const selectNavbar = createSelector(
  selectAppState,
  (state) => state.navbar
);
