import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { translateLoaderFactory } from 'src/app/i18n/i18n.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { HoursComponent } from './hours/hours.component';
import { WorkingHourRoutingModule } from './working-hour-routing.module';

@NgModule({
  declarations: [
    HoursComponent
  ],
  imports: [
    CommonModule,
    WorkingHourRoutingModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
})
export class WorkingHourModule {}