<div mat-dialog-content class="grid-filters">
  <form
    [formGroup]="filterFormGroup"
    fxLayout="row"
    aria-describedby="portal group table"
    class="form-field"
  >
    <mat-form-field  >
      <mat-select color="white" formControlName="key" [(value)]="selectedKey" >
        <mat-option  *ngFor="let key of keys" [value]="key"  >{{ key.text }}</mat-option>
      </mat-select>
    </mat-form-field >
    <ng-container [ngSwitch]="selectedField?.type" >
      <!--MATCHIP-->
      <ng-container *ngSwitchCase="FieldType.MATSELECT">
        <mat-form-field fxFlex>
          <mat-label >{{ "common.modal.filters.value" | translate }}</mat-label>
          <mat-select [(value)]="selectedValue" formControlName="value">
            <mat-option *ngFor="let option of options" [value]="option.id">
              {{ option.description }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="selectedValue">
        <mat-form-field fxFlex>
          <mat-label >{{ "common.modal.filters.value" | translate }}</mat-label>
          <mat-select [(value)]="sub_cat_value" multiple formControlName="sub_cat_value">
            <mat-option *ngFor="let subcategory of get_subcategory(selectedValue)" [value]="subcategory.cod">
              {{ subcategory.tipologia }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
      <!--AUTOCOMPLETE-->
      <ng-container *ngSwitchCase="FieldType.AUTOCOMPLETE">
        <mat-form-field fxFlex >
          <mat-label >{{ "common.modal.filters.value" | translate }}</mat-label>
          <input  
            matInput
            formControlName="value"
            [matAutocomplete]="filterAutocomplete"
          />

          <mat-autocomplete
            #filterAutocomplete="matAutocomplete"
            [displayWith]="displayFn()"
          >
            <mat-option
              *ngFor="let option of filteredOptions | async"
              [value]="option.id"
            >
              {{ option.description }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>
      <!--TEXT-->
      <ng-container *ngSwitchCase="FieldType.TEXT">
        <mat-form-field fxFlex >
          <mat-label>{{ "common.modal.filters.value" | translate }}</mat-label>
          <input matInput formControlName="value"/>
        </mat-form-field>
      </ng-container>
      <!--UPPERCASE-->
      <ng-container *ngSwitchCase="FieldType.UPPERCASE">
        <mat-form-field fxFlex>
          <mat-label>{{ "common.modal.filters.value" | translate }}</mat-label>
          <input matInput formControlName="value" oninput="this.value = this.value.toUpperCase()" />
        </mat-form-field>
      </ng-container>
    </ng-container>
  </form>
</div>
<div mat-dialog-actions class="footer-filters">
  <button mat-button (click)="cancel()">Annulla</button>
  <button mat-button style="color:white;" (click)="applyFilter()">Applica</button>
</div>