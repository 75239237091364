<div class="app-container" fxLayout="column" fxLayoutAlign="space-between">
  <app-header></app-header>
  <div
    fxLayoutAlign="start"
    fxFlex
    style="width: 100%"
    class="content-container"
    [ngClass]="{ loading: (loading$ | async) }"
  >
    <div class="content">
      <mat-sidenav-container
        class="h-100"
        hasBackdrop="true"
        (backdropClick)="closeSidebar()"
      >
        <mat-sidenav fxHide.gt-sm mode="over" [opened]="showNav$ | async">
          <app-navigation-menu
          ></app-navigation-menu>
        </mat-sidenav>
        <mat-sidenav-content>
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </mat-sidenav-content>
      </mat-sidenav-container>
    </div>
    <ng-container *ngTemplateOutlet="loadingTemplate"></ng-container>
  </div>
  <app-footer></app-footer>
</div>
<ng-template #content>
  <router-outlet></router-outlet>
</ng-template>

<ng-template #loadingTemplate>
  <div class="loadTemplate">
    <div class="w-100 h-100" fxLayout="column" fxLayoutAlign="center center">
      <mat-progress-spinner color="primary" mode="indeterminate">
      </mat-progress-spinner>
    </div>
  </div>
</ng-template>
