import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { translateLoaderFactory } from 'src/app/i18n/i18n.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { DataGridComponent } from './data-grid/data-grid.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { DataGridRoutingModule } from './data-grid-routing.module';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { NgOptimizedImage } from '@angular/common';
import { CustomPaginatorComponent } from './buttoned-pagination/buttoned-pagination.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    DataGridComponent,
    CustomPaginatorComponent,
    SearchBarComponent
  ],
  imports: [
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatPaginatorModule,
    NgOptimizedImage,
    MatInputModule,
    MatCardModule,
    MatIconModule,
    MatChipsModule,
    MatSelectModule,
    MatFormFieldModule,
    DataGridRoutingModule,
    MatProgressSpinnerModule,
    MatGridListModule,
    MatDialogModule,
    CommonModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
})
export class DataGridModule {}