
export interface State {
  loadingCount: number;
  navbar: boolean;
}

export const initialState: State = {
  loadingCount: 0,
  navbar: false,
};
