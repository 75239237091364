import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { translateLoaderFactory } from 'src/app/i18n/i18n.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { CookiesComponent } from './cookies/cookies.component';
import { CookiesRoutingModule } from './cookies-routing.module';


@NgModule({
  declarations: [
    CookiesComponent
  ],
  imports: [
    CommonModule,
    CookiesRoutingModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
})
export class CookiesModule {}