import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Store, createAction, props } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { map, tap } from 'rxjs';
import { AppActions } from 'src/app/store/app-store';
//import { AppActions } from '../../store/app-store';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent  {
  constructor(private store: Store) {}

  public toggleMenu() {
    this.store.dispatch(AppActions.toggleNav());
  }
}
