<div class="container">
    <h1>Privacy Policy</h1>
    <h2>INFORMATIVA TRATTAMENTO DATI PERSONALI – ART. 13 REG. 679/16</h2>
    <p>La consultazione di questo sito web e l’utilizzo dei servizi offerti comportano il trattamento di alcune informazioni direttamente o indirettamente a Lei riferibili e sono trattati ed utilizzati in conformità ai principi sanciti dal Regolamento 679/16 come illustrate nella sezione “GDPR e politiche privacy”.</p>
    <h3>GDPR E POLITICHE PRIVACY</h3>
    <p>La protezione dei dati personali trattati in Mquadro S.r.l è un impegno importante. L’entrata in vigore del Reg. 679/16 “Regolamento in materia di protezione dei dati personali”(GDPR) ha fornito l’opportunità per adeguare ulteriormente le attività svolte nella Società ai principi di trasparenza e tutela dei dati personali, nel rispetto dei diritti e delle libertà fondamentali di tutti gli interessati, siano essi dipendenti, collaboratori, clienti, iscritti, utenti o fornitori. La Società ha così implementato un “modello organizzativo privacy” (MOP) che qui si descrive nelle sue linee generali, finalizzato ad analizzare tutti i trattamenti di dati, organizzarli in modo funzionale e gestirli in sicurezza e trasparenza. In questa sezione del sito si riportano inoltre le informazioni sui diritti dell’interessato e le modalità di esercizio nei confronti del Titolare..</p>
    <h3>Diritti</h3>
    <p>L’interessato ha diritto di essere informato su come il TITOLARE tratta i Suoi dati personali, per quali finalità e su altre informazioni previste dall’ art. 13 del Reg. 679/16. A tale fine, il TITOLARE ha predisposto dei processi organizzativi che permettono, all’atto di acquisizione o richiesta dei dati personali” il rilascio di un modello di Informativa creato “ad hoc” a seconda della categoria di interessati a cui l’interessato appartiene (dipendente, cliente, fornitore ecc.). Questo documento permette di informare adeguatamente tutti i soggetti cui i dati si riferiscono su come venga effettuato il trattamento da parte del TITOLARE. Il modello di informativa potrà essere richiesto con apposita domanda a quest’ultimo. In ogni caso il DPO è a sua disposizione per ogni ulteriore spiegazione sul contenuto e sulle modalità di esercizio di tale diritto.

        Diritto di revoca del consenso (art. 13)
        
        Lei ha il diritto di revocare il consenso in qualsiasi momento per tutti quei trattamenti il cui presupposto di legittimità è una Sua manifestazione di consenso. La revoca del consenso non pregiudica la liceità del trattamento precedente.
        
        Diritto di accesso ai dati (art. 15)
        
        Lei potrà richiedere a) le finalità del trattamento; b) le categorie di dati personali in questione; c) i destinatari o le categorie di destinatari a cui i dati personali sono stati o saranno comunicati, in particolare se destinatari di paesi terzi o organizzazioni internazionali; d) quando possibile, il periodo di conservazione dei dati personali previsto oppure, se non è possibile, i criteri utilizzati per determinare tale periodo; e) l’esistenza del diritto dell’interessato di chiedere al titolare del trattamento la rettifica o la cancellazione dei dati personali o la limitazione del trattamento dei dati personali che lo riguardano o di opporsi al loro trattamento; f) il diritto di proporre reclamo a un’autorità di controllo; g) qualora i dati non siano raccolti presso l’interessato, tutte le informazioni disponibili sulla loro origine; h) l’esistenza di un processo decisionale automatizzato, compresa la profilazione di cui all’articolo 22, paragrafi 1 e 4, e, almeno in tali casi, informazioni significative sulla logica utilizzata, nonché l’importanza e le conseguenze previste di tale trattamento per l’interessato. Lei ha il diritto di richiedere una copia dei dati personali oggetto di trattamento.
        
        Diritto di rettifica (art. 16)
        
        Lei ha il diritto di chiedere la rettifica dei dati personali inesatti che lo riguardano e di ottenere l’integrazione dei dati personali incompleti.
        
        Diritto all’oblio (art. 17)
        
        Lei ha il diritto di ottenere dal titolare del trattamento la cancellazione dei dati personali che la riguardano se i dati personali non sono più necessari rispetto alle finalità per le quali sono stati raccolti o altrimenti trattati, se revoca il consenso, se non sussiste alcun motivo legittimo prevalente per procedere al trattamento di profilazione, se i dati sono stati trattati illecitamente, se vi è un obbligo legale di cancellarli; se i dati sono relativi a servizi web resi a minori senza il relativo consenso. La cancellazione può avvenire salvo che sia prevalente il diritto alla libertà di espressione e di informazione, che siano conservati per l’adempimento di un obbligo di legge o per l’esecuzione di un compito svolto nel pubblico interesse oppure nell’esercizio di pubblici poteri, per motivi di interesse pubblico nel settore della sanità, a fini di archiviazione nel pubblico interesse, di ricerca scientifica o storica o a fini statistici o per l’accertamento, l’esercizio o la difesa di un diritto in sede giudiziaria.
        
        Diritto alla limitazione del trattamento (art. 18)
        
        Lei ha il diritto di ottenere dal titolare del trattamento la limitazione del trattamento quando ha contestato l’esattezza dei dati personali (per il periodo necessario al titolare del trattamento per verificare l’esattezza di tali dati personali) o se il trattamento sia illecito, ma Lei si oppone alla cancellazione dei dati personali e chiede invece che ne sia limitato l’utilizzo o se le sono necessari per l’accertamento, l’esercizio o la difesa di un diritto in sede giudiziaria, mentre al Titolare non sono più necessari.
        
        Diritto alla portabilità (art. 20)
        
        Lei ha il diritto di ricevere in un formato strutturato, di uso comune e leggibile da dispositivo automatico i dati personali che la riguardano fornitici ed ha il diritto di trasmetterli a un altro se il trattamento si sia basato sul consenso, sul contratto e se il trattamento sia effettuato con mezzi automatizzati, salvo che il trattamento sia necessario per l’esecuzione di un compito di interesse pubblico o connesso all’esercizio di pubblici poteri e che tale trasmissione non leda il diritto di terzi.
        
        Diritto di rivolgersi all’autorità Garante per la protezione dei dati personali (art. 77).
        
        Fatto salvo ogni altro ricorso amministrativo o giurisdizionale, se Lei ritiene che il trattamento che la riguarda violi il regolamento in materia di protezione dei dati personali, ha il diritto di proporre reclamo ad un’autorità di controllo, segnatamente nello Stato membro in cui risiede abitualmente, lavora oppure del luogo ove si è verificata la presunta violazione.</p>
</div>