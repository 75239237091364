import { state } from '@angular/animations';
import { createReducer, on } from '@ngrx/store';
import { AppActions } from '.';
import { initialState } from './app-store.state';

export const appReducer = createReducer(
  initialState,
  /*on(AppActions.loadUserSuccess, (state, action) => ({
    ...state,
    user: action.user,
  })),
  on(AppActions.loadPermissionSuccess, (state, action) => ({
    ...state,
    permissions: action.permissions,
  })),
  on(AppActions.loadUserFailure, (state) => ({
    ...state,
    user: null,
  })),
  on(AppActions.logOutSuccess, (state) => ({
    ...state,
    user: null,
  })),*/
  on(AppActions.loadingIncrease, (state) => ({
    ...state,
    loadingCount: state.loadingCount + 1,
  })),
  on(AppActions.loadingDecrease, (state) => ({
    ...state,
    loadingCount: state.loadingCount - 1,
  })),
  on(AppActions.hideNav, (state) => ({
    ...state,
    navbar: false,
  })),
  on(AppActions.showNavbar, (state) => ({
    ...state,
    navbar: true,
  })),
  on(AppActions.toggleNav, (state) => ({
    ...state,
    navbar: !state.navbar,
  }))
);
