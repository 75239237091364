<div class="container" fxHide.gt-sm *ngIf="data">
  <div>
    <app-search-bar [data]=" this.keys" (applyFilters)="applyFilters($event)"></app-search-bar>
    <!--<button mat-raised-button (click)="openFilterDialog()" class="filter-button" ><mat-icon>filter_list</mat-icon><mat-label style="font-size: 20px; padding-left:3px; top:0px;">Filtri Ricerca</mat-label></button>-->
    <mat-chip-option color="accent" *ngIf="filters.length > 0" >
      <mat-chip color="accent" *ngFor="let filter of filters" [removable]="true" (removed)="removeFilter(filter)" >
        {{ filter.chip_value }} {{ filter.sub_cat_value }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-option>
  </div>
    <mat-grid-list cols="{{ gridCols }}" rowHeight="{{ rowHeight }}" [gutterSize]="'15px'">
      <mat-grid-tile colspan="{{ colspan }}" rowspan="{{ rowspan }}" *ngFor="let item of data" #tileRef>
        <img mat-card-image [src]="item.img" alt="Image" (click)="openDialog(get_DialogImg(item.img))" class="image">
        <ul class="ul">
          <li><b>Tipologia:</b> {{ get_category(item) }} </li>
          <li><b>Codice:</b>  {{ item.delivery_code }}</li>
          <!--<li><b>Quantità:</b> {{ item.quantity}}</li>-->
        </ul>
      </mat-grid-tile>
    </mat-grid-list>
    <mat-paginator style="display: none !important;" [pageSize]="30"  [pageIndex]="this.page.page " [pageSizeOptions]="pageSizeOptions" [length]="length" showFirstLastButtons></mat-paginator>
    <app-custom-paginator [totalItems]="this.count" [itemsPerPage]="30" (pageChange)="onPageChange($event)"  (pageSizeChange)="onPageSizeChange(30)"></app-custom-paginator>
</div>