import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HoursComponent } from './hours/hours.component';


const routes: Routes = [
  {
    path: 'orari',
    component:HoursComponent,
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WorkingHourRoutingModule { }
