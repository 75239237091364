import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';


enum ActionsTypes {
  SET_TOKEN = '[App] Set token',
  LOAD_USER = '[App] Load User',
  LOAD_USER_SUCCESS = '[App] Load User Success',
  LOAD_USER_FAILED = '[App] Load User Failure',
  LOAD_PERMISSIONS = '[App] Load User Permission',
  LOAD_PERMISSIONS_SUCCESS = '[App] Load User Permission Success',
  LOAD_PERMISSIONS_ERROR = '[App] Load User Permission Error',
  LOADING_INC = '[App] Loading Increase',
  LOADING_DEC = '[App] Loading Decrease',
  LOGOUT = '[App] Logout',
  LOGOUT_SUCCESS = '[App] Logout Success',
  LOGOUT_FAILED = '[App] Logout Error',
  SHOW_NAV = '[App] Show nav',
  HIDE_NAV = '[App] Hide nav',
  TOGGLE_NAV = '[App] Toggle nav',
}

export const setToken = createAction(
  ActionsTypes.SET_TOKEN,
  props<{ token: string }>()
);

export const loadUser = createAction(ActionsTypes.LOAD_USER);

export const loadUserFailure = createAction(
  ActionsTypes.LOAD_USER_FAILED,
  props<{ response: HttpErrorResponse }>()
);

export const loadPermission = createAction(ActionsTypes.LOAD_PERMISSIONS);

export const loadPermissionFailure = createAction(
  ActionsTypes.LOAD_PERMISSIONS_ERROR,
  props<{ response: HttpErrorResponse }>()
);

export const loadingIncrease = createAction(ActionsTypes.LOADING_INC);
export const loadingDecrease = createAction(ActionsTypes.LOADING_DEC);

export const logOut = createAction(ActionsTypes.LOGOUT);
export const logOutSuccess = createAction(ActionsTypes.LOGOUT_SUCCESS);
export const logOutFailure = createAction(
  ActionsTypes.LOGOUT_FAILED,
  props<{ response: HttpErrorResponse }>()
);

export const showNavbar = createAction(ActionsTypes.SHOW_NAV);
export const hideNav = createAction(ActionsTypes.HIDE_NAV);
export const toggleNav = createAction(ActionsTypes.TOGGLE_NAV);
