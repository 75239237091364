import { Component,  OnInit, ViewChild, Renderer2, ElementRef, AfterViewInit  } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImgDialogComponent } from '../../img-dialog/img-dialog.component';
import { Delivery } from '../../models/delivery';
import { DataGridService, Pageable } from '../services/data-grid.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, map, Subscription } from 'rxjs';
import { FieldType, FilterField, GridFiltersComponent } from '../../layout/grid-filters/grid-filters.component';
import { TranslateService } from '@ngx-translate/core';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.scss']
})
export class DataGridComponent implements OnInit {
  @ViewChild('tileRef', { static: false }) tileRef!: ElementRef;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  data!: Delivery[];
  count!:number;

  page: Pageable=this.dataService._page$.getValue();//({ page: 0, pageSize: 50 });//{ page: 0, pageSize: 50 }
  gridCols: number = 3;
  rowHeight: string = '100px';
  colspan: number = 1;
  rowspan: number = 1;
  length = 0
  width:number=200;
  height:number=200;
  public readonly pageSizeOptions: Array<number> = [20, 50, 100, 150, 200, 500, 1000, 1500, 2000]
  filters: { key: string, value: string, chip_value?:string, sub_cat_value?:string }[] = [];
  imgParams:string='?height=240&width=240'
  
  keys: FilterField [] = [//'delivery_category', 'delivery_code',
  /*{
    name:'delivery_category',
    type: FieldType.MATSELECT,
    text: 'Categoria',//this.translate.instant('delivery.grid.filters.delivery_category'),
    options: this.dataService.categorie$.pipe(
      map((categories) =>
      categories.map((cat) => ({ id: cat.id, description: cat.name, json:cat.type }))
      )
    ),
  },*/
  {
    name:'delivery_code',
    type: FieldType.UPPERCASE,
    text: 'Codice'//this.translate.instant('delivery.grid.filters.delivery_code'),
  },
  {
    name:'type',
    type: FieldType.TEXT,
    text: 'Tipologia',//this.translate.instant('delivery.grid.filters.delivery_category'),
    /*options: this.dataService.categorie$.pipe(
      map((categories) =>
      categories.map((cat) => ({ id: cat.id, description: cat.name, json:cat.type }))
      )
    ),*/
  }
  ]; 

  constructor(
    private dataService: DataGridService,
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private translate: TranslateService,
    private elementRef: ElementRef,
  ) { }


  ngOnInit(): void {
    this.dataService.getData().subscribe(response => {
      this.count = response.count;
      this.data = response.results;

    });
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Web 
    ]).subscribe(result => {
      if (result.matches) {
        if (result.breakpoints[Breakpoints.HandsetPortrait]) {
          this.gridCols = 1;
          this.rowHeight = '450px';
          this.colspan = 1;
          this.rowspan = 1;
          this.imgParams='?height=140&width=140';
        }
        if (result.breakpoints[Breakpoints.HandsetLandscape]) {
          this.gridCols = 2;
          this.rowHeight = '450px';
          this.colspan = 1;
          this.rowspan = 1;
          this.imgParams='?height=140&width=140';
        } 
        if (result.breakpoints[Breakpoints.TabletPortrait]) {
          this.gridCols = 2;
          this.rowHeight = '450px';
          this.colspan = 1;
          this.rowspan = 1;
          this.imgParams='?height=260&width=260';
        }
        if (result.breakpoints[Breakpoints.TabletLandscape]) {
          this.gridCols = 6;
          this.rowHeight = '450px';
          this.colspan = 1;
          this.rowspan = 1;
          this.imgParams='?height=260&width=300';
        } 
        if (result.breakpoints[Breakpoints.WebLandscape])  {
          this.gridCols = 6;
          this.rowHeight = '450px';
          this.colspan = 1;
          this.rowspan = 1;
          this.imgParams='?height=240&width=240';
        }
        if (result.breakpoints[Breakpoints.WebPortrait])  {
          this.gridCols = 3;
          this.rowHeight = '450px';
          this.colspan = 1;
          this.rowspan = 1;
          this.imgParams='?height=240&width=240';
        }
      }
    });
  }

  onPageChange(page: number) {
    if (page===undefined || page === null){
      page=0;
    }
    this.dataService.setPage({ page: page, pageSize: 30 });
    this.dataService.getData().subscribe(response => {
      this.count = response.count;
      this.data = response.results;

    });
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Web 
    ]).subscribe(result => {
      if (result.matches) {
        if (result.breakpoints[Breakpoints.HandsetPortrait]) {
          this.gridCols = 1;
          this.rowHeight = '450px';
          this.colspan = 1;
          this.rowspan = 1;
          this.imgParams='?height=140&width=140';
        }
        if (result.breakpoints[Breakpoints.HandsetLandscape]) {
          this.gridCols = 2;
          this.rowHeight = '450px';
          this.colspan = 1;
          this.rowspan = 1;
          this.imgParams='?height=140&width=140';
        } 
        if (result.breakpoints[Breakpoints.TabletPortrait]) {
          this.gridCols = 2;
          this.rowHeight = '450px';
          this.colspan = 1;
          this.rowspan = 1;
          this.imgParams='?height=260&width=260';
        }
        if (result.breakpoints[Breakpoints.TabletLandscape]) {
          this.gridCols = 6;
          this.rowHeight = '450px';
          this.colspan = 1;
          this.rowspan = 1;
          this.imgParams='?height=260&width=300';
        } 
        if (result.breakpoints[Breakpoints.WebLandscape])  {
          this.gridCols = 6;
          this.rowHeight = '450px';
          this.colspan = 1;
          this.rowspan = 1;
          this.imgParams='?height=240&width=240';
        }
        if (result.breakpoints[Breakpoints.WebPortrait])  {
          this.gridCols = 3;
          this.rowHeight = '450px';
          this.colspan = 1;
          this.rowspan = 1;
          this.imgParams='?height=240&width=240';
        }
      }
    });
  }

  onPageSizeChange(newSize: number) {
    //this.page.pageSize = newSize;
    this.dataService.setPage({ page: 0, pageSize: newSize}); // Reset to first page whenever page size changes
  }

  openFilterDialog(): void {
    var width='800px'
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  
    if (screenWidth < 800){
      width=Math.ceil(screenWidth*0.8)+'px'
    } 
    const dialogRef = this.dialog.open(GridFiltersComponent, {
      width: width,
      data: { keys: this.keys }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.applyFilters(result);
      }
    });
  }

  applyFilters(filter: { key: string, value: string, chip_value?:string, sub_cat_value?:string }): void {
    if(filter.chip_value===undefined){
      filter.chip_value=filter.value;
    }
    const index = this.filters.findIndex(f => f.key === filter.key);
    if (index !== -1) {
      this.filters.splice(index, 1);
    }
    this.filters.push(filter);
    this.dataService.getFilteredData(this.filters).subscribe(response => {
      this.count = response.count;
      this.data = response.results; 
    });
  }
  removeFilter(filterToRemove: { key: string, value: string }): void {
    this.filters = this.filters.filter(filter => filter !== filterToRemove);
    this.dataService.getFilteredData(this.filters).subscribe(response => {
      this.count = response.count;
      this.data = response.results;
    });
  }

  get_img(img:string){
    const tileElement = this.elementRef.nativeElement.querySelector('.mat-grid-tile');
    const tileWidth = tileElement.offsetWidth*0.9;
    const tileHeight = tileElement.offsetHeight;
    if(img !== null){
      this.imgParams='?height='+Math.ceil(tileHeight-(tileHeight*0.43))+'&width='+Math.ceil(tileWidth)
      return img+this.imgParams
    }
    return img
  }
  get_width():number{
    const tileElement = this.elementRef.nativeElement.querySelector('.mat-grid-tile');
    console.log(tileElement.offsetWidth)
    return tileElement.offsetWidth*0.9;
  }

  get_height():number{
    const tileElement = this.elementRef.nativeElement.querySelector('.mat-grid-tile');
    return tileElement.offsetHeight*0.43;
  }

  get_DialogImg(img:string){
    if(img !== null){
      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      const width=screenWidth*0.8
      const height=screenHeight//*0.5
      return img//+'?height='+Math.ceil(height)+'&width='+Math.ceil(width)
    }
    return img
  }

  openDialog(imageUrl: string): void {
    const dialogRef = this.dialog.open(ImgDialogComponent, {
      width: '600px',
      data: { imageUrl: imageUrl } // Pass the image URL as data to the dialog
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  get_category(data:Delivery):string | null {
    for(const i in data.delivery_category_item.type){
      if(data.delivery_category_item.type[i].cod === data.type || data.delivery_category_item.type[i].tipologia === data.type){
        return data.delivery_category_item.type[i].tipologia
      }
    }
    return null
  }
}

