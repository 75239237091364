import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DataGridModule } from './features/data/data-grid.module';
import { WorkingHourModule } from './features/working-hour/working-hours.module';

//const loadChildren: () => Promise<typeof DataGridModule> = () => import('./features/data/data-grid.module').then(m => m.DataGridModule);
//const loadHours: () => Promise<typeof WorkingHourModule> = () => import('./features/working-hour/working-hours.module').then(m => m.WorkingHourModule);
const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('./features/data/data-grid.module')
        .then((m) => m.DataGridModule)
  },
  {
    redirectTo: 'home',
    pathMatch: 'full',
    path: '',
  },
  {
    path: 'orari',
    loadChildren: () =>
      import('./features/working-hour/working-hours.module')
        .then((m) => m.WorkingHourModule)
  },
  {
    path: 'cookies',
    loadChildren: () =>
      import('./features/cookies/cookies.module')
        .then((m) => m.CookiesModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./features/privacy-policy/privacy-policy.module')
        .then((m) => m.PrivacyPolicyModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
