<mat-toolbar
  class="background"
  color="primary"
  fxLayout.lt-md="row"
  fxLayoutAlign.lt-md="space-between center"
>
  <button fxShow="true" fxHide.gt-sm="true" mat-icon-button (click)="toggleMenu()">
    <mat-icon>menu</mat-icon>
  </button>
  <img class="logo" routerLink="/" src="/../assets/images/Udine-Stemma.svg" style="height: 100%;">
  <span class="logo" routerLink="/" img src="/../assets/images/Udine-Stemma.svg">Centro del Riuso Udine</span>
  <span class="spacer" fxHide.lt-md fxShow.gt-md></span>
  <app-navigation-menu fxHide.lt-md
  ></app-navigation-menu>
</mat-toolbar>